import { extendTheme } from "@chakra-ui/react"
import fonts from "./fonts"
import colors from "./colors"

const theme = {
  ...fonts,
  ...colors,
  components: {
    Text: {
      baseStyle: {
        fontSize: ["14px", "16px", "18px", "26px"],
        fontWeight: "light",
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 300,
        opacity: 0.7,
      },
    },
    Button: {
      baseStyle: {
        border: "1px solid #000",
        padding: "0 2rem",
        borderRadius: "0",
        fontWeight: "400",
        transition: "all 0.35s ease-in-out",
      },
      variants: {
        solid: {
          background: "none",
          color: "#000",
          padding: "0 0.4rem",
          height: "1.5rem",
        },
        filled: {
          background: "#000",
          color: "#fff",
          padding: "0 0.4rem",
          height: "1.5rem",
        },
        whiteSolid: {
          background: "none",
          border: "1px solid #fff",
          color: "#fff",
          padding: "0 0.4rem",
          height: "1.5rem",
        },
        blackSolid: {
          background: "#000",
          border: "1px solid #000",
          color: "#fff",
          padding: "0 0.4rem",
          height: "1.5rem",
        },
        white: {
          background: "#fff",
          border: "1px solid #fff",
          color: "#000",
          padding: "0 0.4rem",
          height: "1.5rem",
        },
      },
    },
  },
  styles: {
    global: {
      html: {
        overflowX: "hidden",
      },
    },
  },
}

export default extendTheme(theme)
