import React from "react"

const defaultState = {
  people: true,
  setPeople: () => {},
  layout: false,
  setLayout: () => {},
}

const TeamContext = React.createContext(defaultState)

class TeamProvider extends React.Component {
  state = {
    people: true,
    layout: false,
  }
  setPeople = value => {
    this.setState({
      people: value,
    })
  }
  setLayout = value => {
    this.setState({
      layout: value,
    })
  }
  render() {
    const { children } = this.props
    const { people, layout } = this.state
    return (
      <TeamContext.Provider
        value={{
          people,
          setPeople: this.setPeople,
          layout,
          setLayout: this.setLayout,
        }}
      >
        {children}
      </TeamContext.Provider>
    )
  }
}

export default TeamContext

export { TeamProvider }
